//@import "src/styles/variables";

// Banner
.nr-b {

  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;

  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 14px;
  overflow: hidden;

  // Link
  & > .nr-b-l {

    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: flex-end;
    justify-items: center;
    align-content: center;
    justify-content: center;

    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;

    // Image
    & > .nr-b-c {

      box-sizing: border-box;
      padding: 2em 1em;
      margin: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;

      background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.5), transparent);
      border-radius: 6rem;
      overflow: hidden;

      // Title
      & > .nr-b-c-t {
        width: 100%;
        font-size: 16px;
      }

      // Image alt (visible)
      & > .nr-b-c-a {
        width: 100%;
        margin-top: 1em;
        font-size: 18px;
        margin-bottom: 1em;
      }

    }

    @media only screen and (max-width: 100px) {

      & > .nr-b-c {
        padding: 8px;

        // Title
        & > .nr-b-c-t {
          width: 100%;
          font-size: 14px;
        }

        // Image alt (visible)
        & > .nr-b-c-a {
          font-size: 16px;
        }

      }

    }

    @media only screen and (max-height: 100px) {

      & > .nr-b-c {
        padding: 8px;

        // Title
        & > .nr-b-c-t {
          width: 100%;
          font-size: 16px;
        }

        // Image alt (visible)
        & > .nr-b-c-a {
          font-size: 18px;
          margin-top: 0.5em;
          margin-bottom: 1px;
        }

      }

    }

  }

}
